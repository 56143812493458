import moment from 'moment';
import { setSelectedCategory, setSelectedFile } from '../redux/slices/MasterSlice';

const errorMessageHandler = (responseData) => {
  const errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e?.message ?? e));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const compareStartEndTime = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  // return true if end date should be greater then start date.
  return endTime.isAfter(startTime);
};

const extractReportFilters = (filterValues) => {
  const filters = [];

  if (filterValues?.userData) {
    filters.push({
      column_name: 'user_id',
      value: filterValues.userData.id,
      type: 'exact',
    });
  }
  if (filterValues?.feedback) {
    filters.push({
      column_name: 'reaction',
      value: filterValues.feedback,
      type: 'exact',
    });
  }
  if (filterValues?.startDate && filterValues?.endDate && filterValues?.timeStamp === 'custom') {
    filters.push({
      value: 'custom',
      column_name: 'created_at',
      startDate: filterValues.startDate,
      endDate: filterValues.endDate,
      type: 'exact',
    });
  } else if (filterValues?.timeStamp && filterValues?.timeStamp !== 'custom') {
    filters.push({
      value: filterValues.timeStamp,
      column_name: 'created_at',
      type: 'exact',
    });
  }

  return filters;
};

const settingsEnum = {
  appearance: 'appearance',
  tools: 'tools',
  admin: 'admin',
};

const LearnsEnum = {
  examples: 'example_prompts',
  builder: 'prompt_builder',
};

const handleCategoryClear = () => async (dispatch) => {
  // clear selected category
  dispatch(setSelectedCategory(null));
  localStorage.removeItem('selectedCategory');
  // clear selected file
  dispatch(setSelectedFile(null));
  localStorage.removeItem('selectedFile');
};

export const defaultSettingsKeys = {
  SOURCE_LIMIT: 'SOURCE_LIMIT',
  SIMILARITY_SCORE: 'SIMILARITY_SCORE',
  PERSONALITY_PROMPT: 'PERSONALITY_PROMPT',
  LOGO: 'LOGO',
  CHAT_LOGO: 'CHAT_LOGO',
  WELCOME_IMG: 'WELCOME_IMG',
  PROMPT_LIBRARY: 'PROMPT_LIBRARY',
  PROMPT_BUILDER: 'PROMPT_BUILDER',
  INVITE_USER: 'INVITE_USER',
};
export const themeKeys = {
  dark: 'DARK',
  light: 'LIGHT',
};

export const domainType = [
  { label: 'Exclude Domain', value: 'exclude_domain' },
  { label: 'Include Domain', value: 'include_domain' },
];

export const checkIfGPT4 = (selectedPrompt) =>
  (selectedPrompt?.search_type || '').toLowerCase().includes('gpt4') ||
  (selectedPrompt?.model_name || '').toLowerCase().includes('gpt-4');
export const checkIfCLAUDE3 = (selectedPrompt) => (selectedPrompt?.model_name || '').toLowerCase().includes('claude-3');

export {
  errorMessageHandler,
  extractReportFilters,
  settingsEnum,
  LearnsEnum,
  compareStartEndTime,
  debounce,
  handleCategoryClear,
};
